// i18next-extract-mark-ns-start products-engdyn-rod

import { ENGDYN_NAV } from '@utils/constants';
import { graphql } from 'gatsby';
import React from 'react';

import Aside from '@components/Aside';
import AssetList from '@components/AssetList';
import Banner from '@components/Banner';
import Breadcrumbs, { Breadcrumb } from '@components/Breadcrumbs';
import PageSection from '@components/PageSection';
import Page from '@components/layout/Page';
import SEO from '@components/layout/SEO';
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';

const ProductEngdynPage = ({
	pageContext: {
		breadcrumb: { crumbs },
	},
	data
}: {
	pageContext: {
		breadcrumb: { crumbs: Breadcrumb[] };
	};
	data: any;
}) => {
	const { t } = useTranslation();

	return (
		<Page>
			<SEO
				title={t("seo_title")}
				description={t("seo_description")}
			/>

			<Banner
				title={t("title")}
				image={{
					src: data.banner.childImageSharp.gatsbyImageData,
					alt: '',
				}}
				size="narrow"
			/>

			<Breadcrumbs crumbs={crumbs} />

			<PageSection
				className="section__content section__content--dual"
				layout="none">
				<div className="col xl7 m12 m-spacer page__content page__content--dual">
					<Trans i18nKey="content">
						<p>
							ENGDYN’s simulation capabilities incorporate a range of
							connecting-rod models from a simplified rigid
							representation through to fully 3D compliant or dynamic
							models. By default, ENGDYN simply applies loads to the
							crank-pin and cylinder assuming primary motion. To
							consider the dynamic interaction with the crankshaft,
							and for analysing bearing behaviour due to dynamic
							loading, ENGDYN can be extended to include a dynamic
							model of the connecting rod at each cylinder. As with
							the cylinder-block studies, all required tools to
							incorporate the 3D models are included in the ENGDYN
							environment. In addition, the finite element analysis
							(FEA) interface tool allows users to easily apply the
							ENGDYN results as boundary conditions for further
							structural analyses.
						</p>
						<h2>Specific connecting-rod applications</h2>
						<ul>
							<li>
								Dynamic interaction between the connecting rod and
								crankshaft
							</li>
							<li>
								Big-end and small-end bearing analysis, including
								EHL analysis
							</li>
							<li>Bearing distortion analysis</li>
							<li>Connecting-rod durability</li>
						</ul>
						<h2>Key features</h2>
						<ul>
							<li>Quasi-static and dynamic analysis</li>
							<li>
								Hierarchy of model types including rigid, compliant
								and dynamic models, and half models for symmetric
								rods
							</li>
							<li>Integrated FEA interface for matrix reduction</li>
						</ul>
					</Trans>
				</div>
				<Aside>
					<Aside.Widget
						title={t("_Applications")}
						className="widget__sibling__pages">
						<AssetList>
							{ENGDYN_NAV.map((item, i) => (
								<Link
									key={i}
									to={item.value}
									className="assets__link"
									activeClassName="active">
									{t(item.labelkey) /* i18next-extract-disable-line */}
								</Link>
							))}
						</AssetList>
					</Aside.Widget>
				</Aside>
			</PageSection>
		</Page>
	);
};

export default ProductEngdynPage;

export const pageQuery = graphql`
query ($language: String!) {
	locales: allLocale(
		filter: { ns: { in: ["products-engdyn-rod", "_common"] }, language: { eq: $language } }
	) {
		edges {
			node {
				ns
				data
				language
			}
		}
	}
	banner: file(relativePath: { eq: "content/engdyn-hero.jpg" }) {
		...imageBreaker
	}
}
`;
